// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addBlogDrawerAd .container-fluid{

    width: 100px !important;
    display: flex;
    justify-content: end;
    align-items: end;
}

.editColDataVeh{
    display: flex !important;
}
.editColDataVeh .addBlogDrawerAd{
    width: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Admin/FoundersAdmin/AmbassadorsAdmin.css"],"names":[],"mappings":"AAAA;;IAEI,uBAAuB;IACvB,aAAa;IACb,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,wBAAwB;AAC5B;AACA;IACI,YAAY;AAChB","sourcesContent":[".addBlogDrawerAd .container-fluid{\n\n    width: 100px !important;\n    display: flex;\n    justify-content: end;\n    align-items: end;\n}\n\n.editColDataVeh{\n    display: flex !important;\n}\n.editColDataVeh .addBlogDrawerAd{\n    width: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
