// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.txtPrivacyHead{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 35px;
line-height: 69px;
text-align: center;
color: #000000;

}

.topTextPriPart{
    width: 70%;
    margin: auto;

}

.mainPrivacyPolicy{
    padding-top: 30px;
    padding-bottom: 30px;
}

@media (max-width:600px) {
    .topTextPriPart {
        width: 90%;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/Components/PrivacyPolicy/PrivacyPolicyComp.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,kBAAkB;AAClB,cAAc;;AAEd;;AAEA;IACI,UAAU;IACV,YAAY;;AAEhB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI;QACI,UAAU;IACd;;AAEJ","sourcesContent":[".txtPrivacyHead{\n    font-family: 'Poppins';\nfont-style: normal;\nfont-weight: 500;\nfont-size: 35px;\nline-height: 69px;\ntext-align: center;\ncolor: #000000;\n\n}\n\n.topTextPriPart{\n    width: 70%;\n    margin: auto;\n\n}\n\n.mainPrivacyPolicy{\n    padding-top: 30px;\n    padding-bottom: 30px;\n}\n\n@media (max-width:600px) {\n    .topTextPriPart {\n        width: 90%;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
