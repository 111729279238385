// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainAutoSlider{
/* margin-bottom: 100px; */
    height: 130px;
    display: flex;
    flex-direction: column;
    gap: 50px;
background-color: #170e35;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
}

#root{
    overflow: hidden !important;
}

.leadingCrypTxt{

 
    text-align: center;
  
font-style: normal;
font-weight: 600;
font-size: 22.67px;
font-family: 'Open Sans', sans-serif !important;
line-height: 31px;
/* identical to box height */


color: #FFFFFF;


}
.topOurParLine{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.sliderImgAuto{
    width: 100px;
    height: 40px;
    
    object-fit: contain;
}

.topSliderImgAut{
    display: flex !important;
    justify-content: center;
}
.mainAutoSlider div .slick-slider .slick-arrow{
    display: none !important;
}
.ourParTxt{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 46.07px;
    line-height: 69px;
    
    color: #FFFFFF;
    


}
@media (max-width:600px) {
    .leadingCrypTxt {
        font-size: 16px;
        padding: 0 20px;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/Components/AutoSlider/AutoSlider.css"],"names":[],"mappings":"AAAA;AACA,0BAA0B;IACtB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb,yBAAyB;IACrB;;;8BAG0B;AAC9B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;;;IAGI,kBAAkB;;AAEtB,kBAAkB;AAClB,gBAAgB;AAChB,kBAAkB;AAClB,+CAA+C;AAC/C,iBAAiB;AACjB,4BAA4B;;;AAG5B,cAAc;;;AAGd;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,YAAY;;IAEZ,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;IACxB,uBAAuB;AAC3B;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;;IAEjB,cAAc;;;;AAIlB;AACA;IACI;QACI,eAAe;QACf,eAAe;IACnB;;AAEJ","sourcesContent":[".mainAutoSlider{\n/* margin-bottom: 100px; */\n    height: 130px;\n    display: flex;\n    flex-direction: column;\n    gap: 50px;\nbackground-color: #170e35;\n    /* display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center; */\n}\n\n#root{\n    overflow: hidden !important;\n}\n\n.leadingCrypTxt{\n\n \n    text-align: center;\n  \nfont-style: normal;\nfont-weight: 600;\nfont-size: 22.67px;\nfont-family: 'Open Sans', sans-serif !important;\nline-height: 31px;\n/* identical to box height */\n\n\ncolor: #FFFFFF;\n\n\n}\n.topOurParLine{\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n}\n.sliderImgAuto{\n    width: 100px;\n    height: 40px;\n    \n    object-fit: contain;\n}\n\n.topSliderImgAut{\n    display: flex !important;\n    justify-content: center;\n}\n.mainAutoSlider div .slick-slider .slick-arrow{\n    display: none !important;\n}\n.ourParTxt{\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 46.07px;\n    line-height: 69px;\n    \n    color: #FFFFFF;\n    \n\n\n}\n@media (max-width:600px) {\n    .leadingCrypTxt {\n        font-size: 16px;\n        padding: 0 20px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
