// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verificationMain{
    max-width: 500px;
    margin: auto;
    /* padding-top: 40px; */
}

.codeBtn{
    color: black;
    font-size: 20px;
    padding: 0px 10px;
    border: 2px solid black;
    display: flex;
    cursor: pointer;
    justify-content: center;
    border-radius: 5px;
}

.mainVerifyCode{
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.inputSignupCode{
    width: 600px;
}
.verifyTxt{
    font-size: 24px;
    color: black;
    font-weight: 500;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 30px;
}
@media (max-width:650px) {
    .inputSignupCode{
        width: unset;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/Components/VerificationCode/VerificationCode.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;AACA;IACI,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,kCAAkC;IAClC,oBAAoB;AACxB;AACA;IACI;QACI,YAAY;IAChB;;AAEJ","sourcesContent":[".verificationMain{\n    max-width: 500px;\n    margin: auto;\n    /* padding-top: 40px; */\n}\n\n.codeBtn{\n    color: black;\n    font-size: 20px;\n    padding: 0px 10px;\n    border: 2px solid black;\n    display: flex;\n    cursor: pointer;\n    justify-content: center;\n    border-radius: 5px;\n}\n\n.mainVerifyCode{\n    padding-top: 80px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n}\n\n.inputSignupCode{\n    width: 600px;\n}\n.verifyTxt{\n    font-size: 24px;\n    color: black;\n    font-weight: 500;\n    text-align: center;\n    font-family: 'Poppins', sans-serif;\n    padding-bottom: 30px;\n}\n@media (max-width:650px) {\n    .inputSignupCode{\n        width: unset;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
