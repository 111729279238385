// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topRadioButton{
    display: flex;
    gap: 10px;
    align-items: center;
}
.topRadioButton div{
   color: black;
   font-size: 22px;
}
.topRadioButton input[type='radio'] {
    accent-color: #232323;
    width: 20px;
    height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/AmbassadorForm/AmbassadorForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;AACA;GACG,YAAY;GACZ,eAAe;AAClB;AACA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".topRadioButton{\n    display: flex;\n    gap: 10px;\n    align-items: center;\n}\n.topRadioButton div{\n   color: black;\n   font-size: 22px;\n}\n.topRadioButton input[type='radio'] {\n    accent-color: #232323;\n    width: 20px;\n    height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
