// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topBarMain{
    width: 100%;
    padding: 5px 0px;
    /* height: 65px; */
   --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    background-image: radial-gradient(355% 122% at 0 0, #392679, rgba(79, 5, 153, 0) 50%), radial-gradient(17% 50% at 90% 14%, #392679, rgba(5, 109, 153, 0) 117%);    display: flex;
    box-shadow: 0 0 3px rgb(60 72 88 / 15%);
    justify-content: center;
    align-items: center;
}


.imgOuterSection{
    width: 120px !important;
    height: 60px !important;
}

.topBarMainImg{
width:100%;
height: 100%;
overflow: contain !important;
    /* object-fit: cover; */

}`, "",{"version":3,"sources":["webpack://./src/Components/TopBar/TopBar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;GACnB,kBAAkB;IAEjB,mDAAmD;IACnD,8JAA8J,KAAK,aAAa;IAChL,uCAAuC;IACvC,uBAAuB;IACvB,mBAAmB;AACvB;;;AAGA;IACI,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;AACA,UAAU;AACV,YAAY;AACZ,4BAA4B;IACxB,uBAAuB;;AAE3B","sourcesContent":[".topBarMain{\n    width: 100%;\n    padding: 5px 0px;\n    /* height: 65px; */\n   --tw-bg-opacity: 1;\n   display: flex;\n    background-color: rgb(0 0 0 / var(--tw-bg-opacity));\n    background-image: radial-gradient(355% 122% at 0 0, #392679, rgba(79, 5, 153, 0) 50%), radial-gradient(17% 50% at 90% 14%, #392679, rgba(5, 109, 153, 0) 117%);    display: flex;\n    box-shadow: 0 0 3px rgb(60 72 88 / 15%);\n    justify-content: center;\n    align-items: center;\n}\n\n\n.imgOuterSection{\n    width: 120px !important;\n    height: 60px !important;\n}\n\n.topBarMainImg{\nwidth:100%;\nheight: 100%;\noverflow: contain !important;\n    /* object-fit: cover; */\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
