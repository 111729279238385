// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainComingSoon{
    height: 90vh;
    width: 100%;
    background-color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.comingSoonTxt{
    font-size: 80px;
color: #03BABC;
}

@media (max-width:600px) {
    .comingSoonTxt {
        font-size: 50px;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/Components/CoomingSoon/CoomingSoon.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB,cAAc;AACd;;AAEA;IACI;QACI,eAAe;IACnB;;AAEJ","sourcesContent":[".mainComingSoon{\n    height: 90vh;\n    width: 100%;\n    background-color: #ffff;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.comingSoonTxt{\n    font-size: 80px;\ncolor: #03BABC;\n}\n\n@media (max-width:600px) {\n    .comingSoonTxt {\n        font-size: 50px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
